import { useEffect } from "react";
import { motion } from "framer-motion";

/**
 * Represents the Loader component.
 * Displays an animated loader with SVG icons.
 *
 * @component
 * @param {function} setShowLoader - A function to set whether the loader should be displayed.
 */

const Loader = ({ setShowLoader }) => {
  useEffect(() => {
    // Automatically hide the loader after a delay
    setTimeout(() => {
      setShowLoader(false);
    }, 2800);
  }, [setShowLoader]);

  // Animation variants for the SVG icons
  const iconVariant1 = {
    hidden: {
      pathLength: 0,
      fill: "rgb(19, 26, 34)",
    },
    visible: {
      pathLength: 5,
      fill: "rgb(243, 243, 243)",
      stroke: "rgb(243, 243, 243)",
      strokeWidth: 3,
      scale: 0,
      transition: {
        default: { duration: 3, ease: "easeIn" },
        fill: { duration: 3, ease: [1, 0, 0.8, 1] },
        stroke: { duration: 3, ease: [1, 0, 0.8, 1] },
        scale: { duration: 3, ease: [1, 1, 0.5, 0] },
      },
    },
  };

  const iconVariant2 = {
    hidden: {
      pathLength: 0,
      fill: "rgb(19, 26, 34)",
    },
    visible: {
      pathLength: 5,
      fill: "rgb(72, 163, 198)",
      stroke: "rgb(72, 163, 198)",
      scale: 0,
      strokeWidth: 3,
      transition: {
        default: { duration: 3, ease: "easeIn" },
        fill: { duration: 3, ease: [1, 0, 0.8, 1] },
        stroke: { duration: 3, ease: [1, 0, 0.8, 1] },
        scale: { duration: 3, ease: [1, 1, 0.5, 0] },
      },
    },
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      {/* Animated SVG */}
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 1 920.55 921.26"
        style={{ width: "30vw", height: "30vw" }}
      >
        {/* First SVG path */}
        <motion.path
          d="M460 812.076C496.528 809.558 531.467 801.465 563.9 788.781V788.79C604.186 772.929 640.715 749.967 671.674 721.421C731.992 665.755 771.257 589.09 776.489 503.685H460V51C460 51 673 -20.4998 838.5 270.5H719.5L563.9 143.5V403.844H871.399C876.525 429.872 879.252 456.658 879.252 484C879.252 490.565 879.136 497.12 878.799 503.685C871.968 647.71 791.058 773.142 671.674 845.539C638.441 865.659 602.249 881.732 563.9 892.889C530.783 902.519 495.96 908.639 460 910.5V812.076Z"
          variants={iconVariant2}
          initial="hidden"
          animate="visible"
        />

        {/* Second SVG path */}
        <motion.path
          d="M434.252 684.44V698.986H330.352V336.244L303.604 368.391L277.771 399.344L237.032 448.121L196.399 399.344L170.566 368.391L148.491 341.929C139.732 360.188 132.669 379.437 127.437 399.344C125.048 408.418 123.111 417.714 121.521 427.122C118.678 444.186 117.195 461.676 117.195 479.5C117.195 486.065 117.426 492.62 117.879 499.185C119.469 525.212 124.142 550.369 131.648 574.424C161.576 669.894 235.326 746.994 330.352 784.28C362.785 796.965 397.724 805.058 434.252 807.576V906C398.292 904.139 363.575 898.019 330.352 888.389C210.062 853.288 111.278 770.503 57.8974 662.894C40.1391 627.025 27.5069 588.424 20.685 547.961C18.0641 531.999 16.243 515.703 15.4529 499.185C15.2216 492.62 15 486.065 15 479.5C15 452.158 17.7269 425.372 22.853 399.344C32.8643 348.716 52.0968 301.254 78.9607 258.597C97.5091 229.181 119.594 202.173 144.743 177.886L209.156 255.199L237.041 288.55L330.362 176.784L332.867 173.72L433.568 53H434.021L434.252 53.1111V684.44Z"
          variants={iconVariant1}
          initial="hidden"
          animate="visible"
        />
      </motion.svg>{" "}
    </div>
  );
};

export default Loader;
